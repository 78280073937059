import { register } from "store/reducer-registry";

import { REDUCER_NAME } from "./constants";
import surveyForm from "./reducer";
import * as actions from "./actions";
import * as selectors from "./selectors";

register({ [REDUCER_NAME]: surveyForm });

export { actions, selectors };
