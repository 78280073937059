import { errorTypes } from "app/constants";
import { Translation } from "features/localisation/localisation.types";

export const getErrorMessages = (errorType: errorTypes | null): Translation[] => {
    return !errorType ? errorMappings.default : errorMappings[errorType] || errorMappings.default;
};

const defaultErrors = [Translation.ERROR_genericErrorH1, Translation.ERROR_genericErrorH2];

export const errorMappings = {
    default: defaultErrors,
    [errorTypes.genericParamsError]: defaultErrors,
    [errorTypes.unknownError]: [Translation.ERROR_unknownErrorH1, Translation.ERROR_unknownErrorH2],
    [errorTypes.surveySubmitError]: [Translation.ERROR_surveySubmitH1, Translation.ERROR_surveySubmitH2],
    [errorTypes.surveyExpiredError]: [Translation.ERROR_surveyExpiredH1, Translation.ERROR_surveyExpiredH2],
};
