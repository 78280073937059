export const brandPath = (brandId: number) => `brands/${brandId}`;
export enum fetchingState {
    READY = "ready",
    FETCHING = "fetching",
    COMPLETE = "complete",
}
export enum actionTypes {
    BRAND_GET = "BRAND::GET",
    BRAND_GET_SUCCESS = "BRAND::GET::SUCCESS",
    BRAND_GET_ERROR = "BRAND::GET::ERROR",
}
