import { Dispatch } from "redux";

import { getAxios, getErrorType } from "app/utilities/api";
import { networkErrors, errorTypes } from "app/constants";

import { actionTypes, conversationSurveyFormPath } from "./constants";

export const getSurveyForm = (conversationId: number) => async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.SURVEY_GET });
    const axios = getAxios();

    try {
        const { data } = await axios.get(conversationSurveyFormPath(conversationId));
        dispatch({ type: actionTypes.SURVEY_GET_SUCCESS, payload: data });
        return data;
    } catch (GET_SurveyError: any) {
        const networkError = getErrorType(GET_SurveyError);
        let errorType: errorTypes;
        switch (networkError) {
            case networkErrors.expiredTime:
            case networkErrors.notAvailable:
                errorType = errorTypes.surveyExpiredError;
                break;
            case networkErrors.networkError:
            case networkErrors.unauthorised:
            default:
                errorType = errorTypes.genericParamsError;
        }

        dispatch({ type: actionTypes.SURVEY_GET_ERROR, payload: errorType });
    }
};

export const postSurveyForm = (conversationId: number, payload = {}, completedCallback: VoidFunction) => async (
    dispatch: Dispatch
) => {
    dispatch({ type: actionTypes.SURVEY_POST });
    const axios = getAxios();

    try {
        await axios.post(conversationSurveyFormPath(conversationId), payload);
        dispatch({ type: actionTypes.SURVEY_POST_SUCCESS });
        completedCallback();
    } catch (POST_SurveyError: any) {
        const networkError = getErrorType(POST_SurveyError);
        let errorType: errorTypes;
        switch (networkError) {
            case networkErrors.expiredTime:
                errorType = errorTypes.surveyExpiredError;
                break;
            case networkErrors.resourseAlreadyExists:
                errorType = errorTypes.surveySubmitError;
                break;
            case networkErrors.networkError:
            case networkErrors.unauthorised:
            default:
                errorType = errorTypes.unknownError;
        }
        dispatch({ type: actionTypes.SURVEY_POST_ERROR, payload: errorType });
        completedCallback();
    }
};

export const resetForm = () => ({
    type: actionTypes.RESET,
});
