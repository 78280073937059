import thunk from "redux-thunk";
import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

import { StoreType } from "./types";
import { getStateBackup, backupMiddleware } from "./backup-middleware";
import ReducerRegistry from "./reducer-registry";

const middlewares = compose(composeWithDevTools(applyMiddleware(thunk, backupMiddleware)));
const previousState = getStateBackup();
// create the store from the reducer registry providing previous state.
const store: StoreType = createStore(ReducerRegistry.get(previousState), previousState, middlewares);
ReducerRegistry.subscribe((newReducer) => {
    store.replaceReducer(newReducer);
});

export default store;
