import React from "react";
import { Translate } from "react-localize-redux";
import cx from "classnames";

import { getErrorMessages } from "./utilities";
import { IProps } from "./error-display.types";

/**
 * Displays one or more messages for a given error state.
 * Accepts additional className but provides no styling by default.
 * @param errorType       One of the app constants from errorTypes
 * @param className       optional prop className sent to classNames function
 */
export default function DisplayError({ errorType, className }: IProps): React.ReactElement {
    const [primaryMessage, ...rest] = getErrorMessages(errorType);

    if (!primaryMessage) {
        throw new Error("No valid error to display");
    }

    return (
        <div className={cx("error-display", className)}>
            <div className="error-display__primary">
                <Translate id={primaryMessage} />
            </div>
            {rest.map((message, index) => (
                <div key={message} className={`error-display__line-${index + 1}`}>
                    <Translate id={message} />
                </div>
            ))}
        </div>
    );
}
