import { REDUCER_NAME } from "./reducer";
import { fetchingState } from "./constants";
import { IBrandState } from "./types";

export function getBrand(state: IBrandState) {
    return state[REDUCER_NAME];
}

export function getBrandId(state: IBrandState) {
    return getBrand(state).brandId;
}
export function hasBrandId(state: IBrandState) {
    return getBrandId(state) !== null;
}

export function getBrandLogo(state: IBrandState) {
    return getBrand(state).logo;
}

export function getBrandName(state: IBrandState) {
    return getBrand(state).brand;
}

export function getStatus(state: IBrandState) {
    return getBrand(state).status;
}

export function getError(state: IBrandState) {
    return getBrand(state).error;
}
export function hasError(state: IBrandState) {
    return getError(state) !== null;
}

export function isReady(state: IBrandState) {
    return getStatus(state) === fetchingState.READY;
}
export function isFetching(state: IBrandState) {
    return getStatus(state) === fetchingState.FETCHING;
}
export function isComplete(state: IBrandState) {
    return getStatus(state) === fetchingState.COMPLETE;
}
