import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectors } from "app/state";
import { configure } from "app/utilities/api";

function APIConfigManager() {
    const server = useSelector(selectors.getServerName);
    const surveyToken = useSelector(selectors.getSurveyToken);

    useEffect(() => {
        if (server && surveyToken) {
            configure(server, surveyToken);
        }
    }, [server, surveyToken]);

    return null;
}

export default APIConfigManager;
