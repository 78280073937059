/* Translations for language code 11 (English) */
import { Translation } from "features/localisation/localisation.types";

/* tslint:disable:max-line-length */
const translations: { [key in Translation]: string } = {
    [Translation.ERROR_genericErrorH1]: "Survey Not Found",
    [Translation.BRAND_altText]: "Logo image",
    [Translation.Thanks_pageTitle]: "Survey | Complete",
    [Translation.ERROR_surveyExpiredH2]:
        "Thank you for offering to provide feedback. However, the survey link was only valid for a limited time that has now elapsed.",
    [Translation.HOME_pageTitle]: "Survey | Loading…",
    [Translation.ERROR_pageTitle]: "Survey | Problem",
    [Translation.Thanks_pageH2]: "Thank you for your feedback. We appreciate you taking the time to help us improve.",
    [Translation.ERROR_unknownErrorH2]:
        "We apologise for the inconvenience but there seems to be an issue with the network or perhaps with your internet connection.",
    [Translation.ERROR_surveySubmitH1]: "Duplicate Submission",
    [Translation.HOME_loadingHeadingH1]: "We're just preparing your survey",
    [Translation.SURVEY_sendButtonLabel]: "Submit Feedback",
    [Translation.SURVEY_invalidError]: "Please fix the errors on the form to continue.",
    [Translation.ERROR_unknownErrorH1]: "Unexpected Error",
    [Translation.Thanks_pageH1]: "Survey Submitted",
    [Translation.ERROR_genericErrorH2]:
        "The requested survey could not be found. We apologise for any inconvenience caused.",
    [Translation.ERROR_surveySubmitH2]: "Thank you for your feedback but this survey has already been submitted.",
    [Translation.ERROR_surveyExpiredH1]: "Survey Link Expired",
    [Translation.SURVEY_pageTitle]: "Survey",
    [Translation.SURVEY_requiredField]: "Required",
};

export default translations;
