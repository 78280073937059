import { useContext } from "react";
import { LocalizeContext, TranslateValue, TranslatePlaceholderData, TranslateOptions } from "react-localize-redux";

declare module "react-localize-redux" {
    let LocalizeContext: React.Context<LocalizeContextProps>;
}

/**
 * Makes it easier to access translate and adds a translateAsString
 * @return    object containing translate and translateAsString functions
 */
export default function useTranslate() {
    const { translate } = useContext(LocalizeContext);

    return {
        translate,
        translateAsString: (
            value: TranslateValue,
            data?: TranslatePlaceholderData,
            options?: TranslateOptions
        ): string => translate(value, data, options).toString(),
    };
}
