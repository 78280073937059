import { useEffect } from "react";
import { useSelector } from "react-redux";
import { withLocalize } from "react-localize-redux";

import { getLanguageId } from "app/state/selectors";
import translations from "features/localisation/lang/11";

import { isLanguageRegisteredButInactive, getDictionaryForLanguage } from "./utilities";
import { DefaultLanguageCode } from "./constants";
import { IProps } from "./localisation-manager.types";

/**
 * Manages registering language dictionaries if the languageId changes
 * Also registers the "default" language on load.
 */
export function LocalisationManager({ languages, addTranslationForLanguage, setActiveLanguage }: IProps) {
    const languageId = useSelector(getLanguageId);
    useEffect(() => {
        addTranslationForLanguage(translations, DefaultLanguageCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (languageId && isLanguageRegisteredButInactive(languages, String(languageId))) {
            // It is important to set active language immediately because this must be synchronised with redux state
            setActiveLanguage(String(languageId));
            getDictionaryForLanguage(addTranslationForLanguage, String(languageId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageId]);

    return null;
}

export default withLocalize<IProps>(LocalisationManager);
