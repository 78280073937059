import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";

import { getError } from "app/state/selectors";
import BrandLogo from "features/brand/brand-logo";
import Page, { usePageTransitions } from "components/page";
import { Translation } from "features/localisation/localisation.types";
import DisplayError from "components/error-display";

export default function ErrorPage(): React.ReactElement {
    const { enterComplete } = usePageTransitions(false);
    const errorType = useSelector(getError);

    return (
        <Page className={cx("error-page", { "page-fade--in": !enterComplete })} title={Translation.ERROR_pageTitle}>
            <BrandLogo />
            <DisplayError errorType={errorType} />
        </Page>
    );
}
