import { combineReducers, Reducer, ReducersMapObject } from "redux";

import { IApplicationState } from "./types";

type reducerMap = ReducersMapObject<IApplicationState, any>;
type listenerFn = (reducer: Reducer) => void;

const registeredReducers: string[] = [];
let currentReducers: reducerMap = {};
let listeners: listenerFn[] = [];

/**
 * register a new set of reducers from a map of key/reducer pairs
 * Keys will be treated as unique identifiers for reducers, there is no interface to replace registered reducer slices.
 * @param  reducersToRegister  must be a plain object, not a combined reducer.
 */
export function register(reducersToRegister: reducerMap) {
    // get an array on NEW reducers
    // if all keys are already known this array will be empty making this function a no-op.
    const keys = Object.keys(reducersToRegister).filter((key) => !registeredReducers.includes(key));
    keys.forEach((reducerName) => {
        // add the reducer to the currentReducers map then add the key to the registeredReducers array
        currentReducers[reducerName] = reducersToRegister[reducerName];
        registeredReducers.push(reducerName);
    });
    if (keys.length) {
        const reducer = combineReducers(currentReducers);
        // notify subscribers about any updates
        listeners.forEach((listener) => {
            listener(reducer);
        });
    }
}

/**
 * Add a subscriber to be called every time a new reducer is registered
 * - listeners are only called if a reducer is actually new
 */
export function subscribe(listener: listenerFn) {
    listeners.push(listener);
}

/**
 * Unsubscribe a function that was previously registered as a listener.
 */
export function unsubscribe(listener: listenerFn): void {
    const index = listeners.indexOf(listener);
    if (index >= 0) {
        listeners = listeners.slice(0, index).concat(listeners.slice(index + 1));
    }
}

/**
 * Creates a no-op reducer, which always returns the initialState it is instanciated with
 */
const placeholderReducer = (initialState: any): Reducer => () => {
    return initialState;
};

/**
 * Get a reducer including all currently registered reducer slices
 * - returns a combined reducer including all registered reducer slices.
 * - if initialState is provided placeholder reducers are created to hold
 * state until/if a reducer for that slice is registered.
 * - returns a no-op reducer if no reducers are registered AND there is no initial state.
 */
export function get(initialState?: { [key: string]: any }): Reducer {
    if (registeredReducers.length < 1 && !initialState) {
        return (state) => state;
    }
    if (initialState) {
        for (const key in initialState) {
            if (!currentReducers[key]) {
                currentReducers[key] = placeholderReducer(initialState[key]);
            }
        }
    }
    return combineReducers(currentReducers);
}

export default {
    get,
    subscribe,
    unsubscribe,
    register,
};
