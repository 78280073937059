import Axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from "axios";

import { networkErrors } from "app/constants";

const SERVER_PATH = `edgetier.com/api`;

let axios: AxiosInstance;

/**
 * Get the server path for a request, one way for local development and another way for other environments
 * @param serverName The customer/environment name "my-cust-prod".
 * @returns          Full API URL or proxied api url for local dev
 */
function getApiUrl(serverName: string): string {
    if (process.env.NODE_ENV === "development") {
        return `${window.location.origin}/api`;
    } else {
        return `https://${serverName}.${SERVER_PATH}`;
    }
}
export function setApiUrl(serverName: string): void {
    axios = Axios.create({ baseURL: getApiUrl(serverName) });
}

export function setHeaders(token: string): void {
    // Add a version header on each request in case the backend needs to make some decision.
    getAxios().interceptors.request.use(function (configuration: AxiosRequestConfig): AxiosRequestConfig {
        configuration.headers["Authorization"] = `Bearer ${token}`;
        return configuration;
    });
}

export function configure(serverName: string, token: string) {
    setApiUrl(serverName);
    setHeaders(token);
    return getAxios();
}

export function getErrorType(axiosError: AxiosError): networkErrors {
    if (typeof axiosError.response === "undefined") {
        return networkErrors.networkError;
    } else if (axiosError.response.status in networkErrors) {
        return axiosError.response.status;
    }
    return networkErrors.unknownError;
}

export function getAxios() {
    if (!axios) {
        throw new Error("server not configured");
    }
    return axios;
}
