export const conversationSurveyFormPath = (conversationId: number) => `conversations/${conversationId}/survey-forms`;
export const REDUCER_NAME = "survey";
export enum surveyState {
    INITIAL = "initial",
    READY = "ready",
    FETCHING = "fetching",
    POSTING = "posting",
    COMPLETE = "complete",
    ERROR = "error",
}
export enum actionTypes {
    RESET = "SURVEY::RESET",
    SURVEY_GET = "SURVEY::GET",
    SURVEY_GET_SUCCESS = "SURVEY::GET::SUCCESS",
    SURVEY_GET_ERROR = "SURVEY::GET::ERROR",

    SURVEY_POST = "SURVEY::POST",
    SURVEY_POST_SUCCESS = "SURVEY::POST::SUCCESS",
    SURVEY_POST_ERROR = "SURVEY::POST::ERROR",
}
