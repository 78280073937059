import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";
import cx from "classnames";

import Page, { usePageTransitions } from "components/page";
import { selectors } from "app/state";
import { setSearchParams, appParamsFailed, appIsReady } from "app/state/actions";
import { actions as brandActions } from "features/brand/state";
import { actions as surveyActions } from "features/survey/state";
import { Translation } from "features/localisation/localisation.types";

import { pages } from "../index";

import LoadingSurvey from "./loading-survey";

/**
 * Initialises the app if search parameters are present and represent a survey
 * - handles bad deeplinks
 * - handles fetching data from the server when params are good
 * - uses page transitions to fadein always and fade out on a happy path
 */
function HomePage() {
    const [hasRendered, setHasRendered] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const hasError = useSelector(selectors.hasError) && hasRendered;
    const isReady = useSelector(selectors.isSurveyReady);
    const { enterComplete, exitComplete } = usePageTransitions(isReady && hasRendered && !hasError, undefined, 1400);

    /**
     * Effect to initialise the app from the search params of inbound deeplinks
     * - initiates the requests to get surveys and brand info
     */
    useEffect(() => {
        if (!location.search && isReady) {
            setHasRendered(true);
        } else if (!location.search) {
            dispatch(appParamsFailed());
            setHasRendered(true);
        } else if (location.search) {
            setSearchParams(location.search)(dispatch)
                .then(params => {
                    dispatch(brandActions.getBrandInfo(params.brandId));
                    surveyActions
                        .getSurveyForm(params.conversationId)(dispatch)
                        .then(() => {
                            dispatch(appIsReady());
                        });
                })
                .catch(() => {
                    dispatch(appParamsFailed());
                })
                .finally(() => {
                    setHasRendered(true);
                });
        }
    }, [dispatch, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

    // Prerender loader as initial markup
    const isSnap = navigator.userAgent === "ReactSnap";
    if (!isSnap && hasError) {
        return <Redirect to={pages.ERROR} />;
    }
    if (!isSnap && exitComplete) {
        return <Redirect to={pages.SURVEY} />;
    }

    return (
        <Page
            className={cx("home-page", { "page-fade--out": false, "page-fade--in": !enterComplete })}
            title={Translation.HOME_pageTitle}
        >
            <LoadingSurvey />
        </Page>
    );
}

export default HomePage;
