import { ThunkDispatch } from "redux-thunk";

import { parse, validate } from "app/utilities/deeplink";
import { configure } from "app/utilities/api";

import { actionTypes } from "./constants";
import { ISettings } from "./types";

/**
 * action creator for setting APP_READY
 */
export function appIsReady() {
    return { type: actionTypes.APP_READY };
}

/**
 * used to dispatch the appParamsFailed action
 */
export function appParamsFailed() {
    return { type: actionTypes.APP_PARAMS_FAIL };
}

/**
 * Try to interpret the search parameters of the incoming link.
 * - Resolve with the params or reject with nothing
 * - Configure API with server and surveyToken
 * - Rejects on any issues
 * @param   searchParams?:  the search params as a URIEncoded query string
 * @return                  (dispatch) => Promise<Parsed app settings object>
 */
export function setSearchParams(searchParams?: string) {
    return function (dispatch: ThunkDispatch<any, any, any>) {
        return new Promise<ISettings>((resolve, reject) => {
            if (searchParams) {
                const params = parse(searchParams);

                if (params && validate(params)) {
                    dispatch({
                        type: actionTypes.APP_INITIALISE,
                        payload: params,
                    });
                    configure(params.server, params.surveyToken);
                    resolve(params);
                } else {
                    reject();
                }
            } else {
                reject();
            }
        });
    };
}
