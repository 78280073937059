import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import { Translate } from "react-localize-redux";

import BrandLogo from "features/brand/brand-logo";
import Page, { usePageTransitions } from "components/page";
import { Translation } from "features/localisation/localisation.types";
import { actions } from "features/survey/state";

const ThanksPage: React.FC = () => {
    const dispatch = useDispatch();
    const { enterComplete } = usePageTransitions(false);

    useEffect(() => {
        // Clear any form in state when this page loads
        dispatch(actions.resetForm());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Page className={cx("thanks-page", { "page-fade--in": !enterComplete })} title={Translation.Thanks_pageTitle}>
            <BrandLogo />
            <h1>
                <Translate id={Translation.Thanks_pageH1}>Thank you for giving us feedback</Translate>
            </h1>
            <h2>
                <Translate id={Translation.Thanks_pageH2}>
                    We really appreciate you taking the time to help us improve
                </Translate>
            </h2>
        </Page>
    );
};

export default ThanksPage;
