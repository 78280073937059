import React from "react";
import { Spinner } from "@edgetier/components";

import BrandLogo from "features/brand/brand-logo";

const LoadingSurvey: React.FC = () => {
    return (
        <div className="loading-survey">
            <BrandLogo />
            <Spinner />
        </div>
    );
};

export default LoadingSurvey;
