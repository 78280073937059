import { surveyState, REDUCER_NAME } from "./constants";
import { ISurveyFormState } from "./types";

export function getSlice(state: ISurveyFormState) {
    return state[REDUCER_NAME];
}

export function getConversationId(state: ISurveyFormState) {
    return getSlice(state).conversationId;
}
export function hasConversationId(state: ISurveyFormState) {
    return getConversationId(state) !== null;
}

export function getFormId(state: ISurveyFormState) {
    const { surveyForm } = getSlice(state);
    return surveyForm ? surveyForm.formId : surveyForm;
}

export function getForm(state: ISurveyFormState) {
    const { surveyForm } = getSlice(state);
    return surveyForm ? surveyForm.formComponents : surveyForm;
}

export function getStatus(state: ISurveyFormState) {
    return getSlice(state).status;
}

export function getError(state: ISurveyFormState) {
    return getSlice(state).error;
}
export function hasError(state: ISurveyFormState) {
    return getError(state) !== null;
}

export function isReady(state: ISurveyFormState) {
    return getStatus(state) === surveyState.READY;
}
export function isFetching(state: ISurveyFormState) {
    return getStatus(state) === surveyState.FETCHING;
}
export function isPosting(state: ISurveyFormState) {
    return getStatus(state) === surveyState.POSTING;
}
export function isBusy(state: ISurveyFormState) {
    return isPosting(state) || isFetching(state);
}
export function isComplete(state: ISurveyFormState) {
    return getStatus(state) === surveyState.COMPLETE;
}
