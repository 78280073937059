export const REDUCER_NAME = "app";

export enum appStates {
    INITIAL = "init",
    PREPARING = "prep",
    SURVEY_READY = "survey",
    COMPLETE = "complete",
    ERROR = "error",
}
export enum actionTypes {
    APP_INITIALISE = "APP::INITIALISE",
    APP_READY = "APP::READY",
    APP_PARAMS_FAIL = "APP::BAD_PARAMS",
}
