import { combineReducers, AnyAction } from "redux";
import { IForm } from "@edgetier/types";

import { actionTypes as appActionTypes } from "app/state/constants";
import { errorTypes } from "app/constants";

import { actionTypes, surveyState } from "./constants";

export const conversationId = (state: number | null = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_INITIALISE:
            return action.payload.conversationId as number;
        case appActionTypes.APP_PARAMS_FAIL:
            return null;
        default:
            return state;
    }
};

export const status = (state: surveyState = surveyState.INITIAL, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_INITIALISE:
        case appActionTypes.APP_PARAMS_FAIL:
            return surveyState.INITIAL;
        case actionTypes.SURVEY_GET:
            return surveyState.FETCHING;
        case actionTypes.SURVEY_POST:
            return surveyState.POSTING;
        case actionTypes.SURVEY_GET_ERROR:
        case actionTypes.SURVEY_POST_ERROR:
            return surveyState.ERROR;
        case actionTypes.SURVEY_GET_SUCCESS:
            return surveyState.READY;
        case actionTypes.SURVEY_POST_SUCCESS:
            return surveyState.COMPLETE;
        default:
            return state;
    }
};

export const error = (state: errorTypes | null = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_INITIALISE:
        case appActionTypes.APP_PARAMS_FAIL:
        case actionTypes.SURVEY_GET:
        case actionTypes.SURVEY_POST:
            return null;
        case actionTypes.SURVEY_GET_ERROR:
        case actionTypes.SURVEY_POST_ERROR:
            return action.payload as errorTypes;
        default:
            return state;
    }
};

export const surveyForm = (state: null | IForm = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_INITIALISE:
        case appActionTypes.APP_PARAMS_FAIL:
        case actionTypes.RESET:
        case actionTypes.SURVEY_GET:
            return null;
        case actionTypes.SURVEY_GET_SUCCESS:
            return action.payload as IForm;
        default:
            return state;
    }
};

export default combineReducers({
    conversationId,
    status,
    error,
    surveyForm,
});
