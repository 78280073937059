export enum Translation {
    HOME_pageTitle = "HOME_pageTitle",
    HOME_loadingHeadingH1 = "HOME_loadingHeadingH1",
    BRAND_altText = "BRAND_altText",
    SURVEY_pageTitle = "SURVEY_pageTitle",
    SURVEY_invalidError = "SURVEY_invalidError",
    SURVEY_requiredField = "SURVEY_requiredField",
    SURVEY_sendButtonLabel = "SURVEY_sendButtonLabel",
    ERROR_pageTitle = "ERROR_pageTitle",
    ERROR_genericErrorH1 = "ERROR_genericErrorH1",
    ERROR_genericErrorH2 = "ERROR_genericErrorH2",
    ERROR_unknownErrorH1 = "ERROR_unknownErrorH1",
    ERROR_unknownErrorH2 = "ERROR_unknownErrorH2",
    ERROR_surveySubmitH1 = "ERROR_surveySubmitH1",
    ERROR_surveySubmitH2 = "ERROR_surveySubmitH2",
    ERROR_surveyExpiredH1 = "ERROR_surveyExpiredH1",
    ERROR_surveyExpiredH2 = "ERROR_surveyExpiredH2",
    Thanks_pageTitle = "Thanks_pageTitle",
    Thanks_pageH1 = "Thanks_pageH1",
    Thanks_pageH2 = "Thanks_pageH2",
}
