/**
 * Check to see if alanguage is registered in the localisation context (from initialisation) and that it is not already active
 * @param   languages:                  array of languages in {code, name, active} format
 * @param   languageId:                 the languageId to find
 * @return                              True or false
 */
export function isLanguageRegisteredButInactive(
    languages: { code: string; active: boolean }[],
    languageId: string | number
): Boolean {
    const index = languages.findIndex(({ code }) => code === languageId);
    return index >= 0 && !languages[index].active;
}

/**
 * Dynamically import a translation dictionary and send resulting data to
 * @param   addTranslationForLanguage:  From react-localize-redux, but context aware so must be passed from component
 * @param   languageId:                 A string languageCode as per EdgeTier global language definitions
 * @return                              Promise resolves or rejects
 */
export function getDictionaryForLanguage(addTranslationForLanguage: Function, languageId: string) {
    return new Promise<void>((resolve, reject) => {
        // dynamic imports need an onrejected callback as catch will never be called.
        import(/* webpackChunkName: "locales/[request]" */ `features/localisation/lang/${languageId}.ts`).then(
            (data) => {
                addTranslationForLanguage(data.default, languageId);
                resolve();
            },
            (dynamicImportError) => {
                console.error(dynamicImportError);
                reject();
            }
        );
    });
}
