import Qs from "qs";
import { object, string, number } from "yup";

export function parse(searchString: string) {
    try {
        const { survey_token, language_id, brand_id, conversation_id, server } = Qs.parse(searchString, {
            ignoreQueryPrefix: true,
        });
        return requestSchema.cast({
            surveyToken: survey_token,
            languageId: language_id,
            brandId: brand_id,
            conversationId: conversation_id,
            server,
        });
    } catch (e) {
        return null;
    }
}

export const requestSchema = object().shape({
    surveyToken: string().required(),
    languageId: number().required().integer().positive(),
    brandId: number().required().integer().positive(),
    conversationId: number().required().integer().positive(),
    server: string()
        .trim()
        .lowercase()
        .matches(/[a-z-_0-9]+/)
        .required(),
});

export function validate(params: ReturnType<typeof parse>) {
    try {
        // validateSync errors on invalid but returns the object if OK, we want a boolean instead
        requestSchema.validateSync(params);
        return true;
    } catch (e) {
        return false;
    }
}
