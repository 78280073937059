import { combineReducers, AnyAction } from "redux";

import { actionTypes as appActionTypes } from "app/state/constants";
import { errorTypes } from "app/constants";

import { actionTypes, fetchingState } from "./constants";

export const REDUCER_NAME = "brand";

export const brandId = (state: number | null = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_PARAMS_FAIL:
            return null;
        case appActionTypes.APP_INITIALISE:
            return action.payload.brandId as number;
        default:
            return state;
    }
};

export const status = (state: fetchingState = fetchingState.READY, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_PARAMS_FAIL:
        case appActionTypes.APP_INITIALISE:
            return fetchingState.READY;
        case actionTypes.BRAND_GET:
            return fetchingState.FETCHING;
        case actionTypes.BRAND_GET_ERROR:
        case actionTypes.BRAND_GET_SUCCESS:
            return fetchingState.COMPLETE;
        default:
            return state;
    }
};

export const error = (state: errorTypes | null = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case appActionTypes.APP_PARAMS_FAIL:
        case appActionTypes.APP_INITIALISE:
        case actionTypes.BRAND_GET:
            return null;
        case actionTypes.BRAND_GET_ERROR:
            return action.payload as errorTypes;
        default:
            return state;
    }
};

export const plainAttribute = <T>(attributeName: string, initialState: T) => (
    state: T = initialState,
    action: AnyAction = { type: undefined }
) => {
    switch (action.type) {
        case appActionTypes.APP_PARAMS_FAIL:
        case appActionTypes.APP_INITIALISE:
        case actionTypes.BRAND_GET:
            return initialState;
        case actionTypes.BRAND_GET_SUCCESS:
            return action.payload[attributeName] as T;
        default:
            return state;
    }
};

export default combineReducers({
    brandId,
    status,
    error,
    logo: plainAttribute<string | null>("logo", null),
    brand: plainAttribute<string | null>("brand", null),
});
