import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { LocalizeProvider } from "react-localize-redux";

import store from "store";
import Home from "pages/app-home";
import Thanks from "pages/thanks";
import ErrorPage from "pages/error";
import { pages } from "pages";
import { initialisePayload } from "features/localisation/localisation-manager/constants";
import LocalisationManager from "features/localisation/localisation-manager/localisation-manager";

import APIConfigManager from "./api-config-manager";
import "./state/reducers"; // eslint-disable-line import/no-unassigned-import

const Survey = React.lazy(() => import("pages/survey"));

const AppContainer: React.FC = () => {
    return (
        <div className="survey-app-container">
            <LocalizeProvider initialize={initialisePayload}>
                <Provider store={store}>
                    <LocalisationManager />
                    <APIConfigManager />
                    <Suspense fallback={<div />}>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path={pages.HOME}>
                                    <Home />
                                </Route>
                                <Route exact path={pages.SURVEY}>
                                    <Survey />
                                </Route>
                                <Route exact path={pages.THANKS}>
                                    <Thanks />
                                </Route>
                                <Route exact path={pages.ERROR}>
                                    <ErrorPage />
                                </Route>
                                <Route default>
                                    <ErrorPage />
                                </Route>
                            </Switch>
                        </BrowserRouter>
                    </Suspense>
                </Provider>
            </LocalizeProvider>
        </div>
    );
};

export default AppContainer;
