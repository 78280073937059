import { renderToString } from "react-dom/server";
import { LocalizedElement } from "react-localize-redux";
import { Language } from "@edgetier/types";

export const DefaultLanguageCode = "11";
export const Locales: { name: string; code: string }[] = [];

Object.values(Language).forEach((languageCode) => {
    if (typeof languageCode === "number") {
        const code = String(languageCode);
        Locales.push({ name: code, code });
    }
});

export const initialisePayload = {
    languages: Locales,
    options: {
        onMissingTranslation: ({ defaultTranslation }: { defaultTranslation: LocalizedElement }) => defaultTranslation,
        renderToStaticMarkup: renderToString,
        renderInnerHtml: false,
        defaultLanguage: DefaultLanguageCode,
    },
};
