export enum errorTypes {
    genericParamsError,
    unknownError,
    surveySubmitError,
    surveyExpiredError,
}
export enum networkErrors {
    networkError,
    unknownError,
    unauthorised = 401,
    expiredTime = 403,
    resourseAlreadyExists = 409,
    notAvailable,
}
