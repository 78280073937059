import { combineReducers, AnyAction } from "redux";

import { errorTypes } from "../constants";

import { appStates, actionTypes } from "./constants";
import { ISettings } from "./types";

export const status = (state: appStates = appStates.INITIAL, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case actionTypes.APP_INITIALISE:
            return appStates.PREPARING;
        case actionTypes.APP_READY:
            return appStates.SURVEY_READY;
        case actionTypes.APP_PARAMS_FAIL:
            return appStates.ERROR;
        default:
            return state;
    }
};

export const error = (state: errorTypes | null = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case actionTypes.APP_PARAMS_FAIL:
            return errorTypes.genericParamsError;
        case actionTypes.APP_INITIALISE:
            return null;
        default:
            return state;
    }
};

export const settings = (state: ISettings | null = null, action: AnyAction = { type: undefined }) => {
    switch (action.type) {
        case actionTypes.APP_PARAMS_FAIL:
            return null;
        case actionTypes.APP_INITIALISE:
            return action.payload as ISettings;
        default:
            return state;
    }
};

export const reducers = { status, settings, error };

export default combineReducers(reducers);
