import { Middleware } from "redux";

import { IApplicationState } from "./types";

export const backupKey = "et-surveys";
export const expiresWindow = 3600000;

/**
 * get a replica of app state from localStorage
 * - performs expiration comparison
 * - ensures app version has not changed
 * - clears localStorage key if time or version causes state to be invalidated
 * - returns undefined if unable to get a valid state object
 */
export const getStateBackup = () => {
    try {
        const backup = window.localStorage.getItem(backupKey);
        if (backup) {
            const { state, version, expires } = JSON.parse(backup);
            if (expires > Date.now() && version === process.env.REACT_APP_VERSION) {
                return state as IApplicationState;
            }
            window.localStorage.removeItem(backupKey);
            return;
        }
    } catch {
        window.localStorage.removeItem(backupKey);
        return;
    }
};

/**
 * A redux middleware that backs up the current state to localStorage after every action
 * - adds an expires flag set for a set interval from the current time
 * - adds a version flag to prevent old state shapes from causing issues in subsequent releases
 */
export const backupMiddleware: Middleware = ({ getState }) => (next) => (action) => {
    const result = next(action);
    window.localStorage.setItem(
        backupKey,
        JSON.stringify({
            expires: Date.now() + expiresWindow,
            version: process.env.REACT_APP_VERSION,
            state: getState(),
        })
    );
    return result;
};
