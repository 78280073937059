import React from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";

import useTranslate from "features/localisation/useTranslate";

import "./page.scss";
import { IProps } from "./page.types";

const Page: React.FC<IProps> = ({ children, title, className }) => {
    const { translateAsString } = useTranslate();

    return (
        <div className={cx("page", className)}>
            {title && typeof title === "string" && (
                <Helmet>
                    <title>{translateAsString(title)}</title>
                </Helmet>
            )}
            {children}
        </div>
    );
};

export default Page;
