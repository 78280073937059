import { useRef, useEffect, useState } from "react";

export default function usePageTransitions(shouldExit: boolean = false, enterMs: number = 250, exitMs: number = 400) {
    const exitTimer = useRef<number | null>();
    const [exitComplete, setExitComplete] = useState<boolean>(shouldExit);

    const enterTimer = useRef<number | null>();
    const [enterComplete, setEnterComplete] = useState<boolean>(false);

    useEffect(() => {
        if (shouldExit) {
            exitTimer.current = window.setTimeout(() => {
                setExitComplete(true);
            }, exitMs);
        } else if (exitTimer.current) {
            clearTimeout(exitTimer.current);
        }
        return () => {
            if (exitTimer.current) {
                clearTimeout(exitTimer.current);
            }
        };
    }, [shouldExit, exitMs]);

    useEffect(() => {
        enterTimer.current = window.setTimeout(() => {
            setEnterComplete(true);
        }, enterMs);

        return () => {
            if (enterTimer.current) {
                clearTimeout(enterTimer.current);
            }
        };
    }, [enterMs]);

    return { enterComplete, exitComplete };
}
