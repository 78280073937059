import { register } from "store/reducer-registry";

import reducer, { REDUCER_NAME } from "./reducer";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as constants from "./constants";

register({ [REDUCER_NAME]: reducer });

export { selectors, constants, actions };
