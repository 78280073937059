import { REDUCER_NAME, appStates } from "./constants";
import { IAppState } from "./types";

export const getAppSlice = (state: IAppState) => {
    return state[REDUCER_NAME];
};

export const hasError = (state: IAppState) => {
    return getAppSlice(state).status === appStates.ERROR;
};

export const isSurveyReady = (state: IAppState) => {
    return getAppSlice(state).status === appStates.SURVEY_READY;
};

export const getSettings = (state: IAppState) => {
    return getAppSlice(state).settings;
};
export const getServerName = (state: IAppState) => {
    const settings = getSettings(state);
    return settings ? settings.server : undefined;
};
export const getLanguageId = (state: IAppState) => {
    const settings = getSettings(state);
    return settings ? settings.languageId : undefined;
};
export const getSurveyToken = (state: IAppState) => {
    const settings = getSettings(state);
    return settings ? settings.surveyToken : undefined;
};
export const getError = (state: IAppState) => {
    return getAppSlice(state).error;
};
