/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";

import { IProps } from "./image-lazy.types";

export default function ImageLazy({ onLoad, onError, className, ...imageProps }: IProps) {
    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);

    if (!loaded && !errored) {
        return (
            <img
                {...imageProps}
                style={{ display: "none" }}
                onLoad={() => {
                    onLoad && onLoad();
                    setLoaded(true);
                }}
                onError={() => {
                    onError && onError();
                    setErrored(true);
                }}
            />
        );
    }
    if (errored) {
        return null;
    }
    return <img className={className} {...imageProps} />;
}
