import { Dispatch } from "redux";

import { getAxios, getErrorType } from "app/utilities/api";
import { errorTypes, networkErrors } from "app/constants";

import { actionTypes, brandPath } from "./constants";

export const getBrandInfo = (brandId: number) => (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.BRAND_GET });

    const axios = getAxios();

    return axios
        .get(brandPath(brandId))
        .then(({ data }) => {
            dispatch({ type: actionTypes.BRAND_GET_SUCCESS, payload: data });
            return data;
        })
        .catch((GET_BrandError) => {
            const networkError = getErrorType(GET_BrandError);
            let errorType: errorTypes;
            switch (networkError) {
                case networkErrors.expiredTime:
                    errorType = errorTypes.surveyExpiredError;
                    break;
                case networkErrors.notAvailable:
                case networkErrors.networkError:
                case networkErrors.unauthorised:
                default:
                    errorType = errorTypes.genericParamsError;
            }
            dispatch({ type: actionTypes.BRAND_GET_ERROR, payload: errorType });
        });
};
